var app;
(function (app) {
    var statePreloader;
    (function (statePreloader) {
        'use strict';
        var DirectiveController = /** @class */ (function () {
            function DirectiveController() {
            }
            return DirectiveController;
        }());
        angular.module('app.statePreloader', []);
        angular.module('app.statePreloader')
            .directive("statePreloader", ["$rootScope", function ($rootScope) {
            return {
                template: template(),
                controller: DirectiveController,
                link: function (scope, elem, attr, ctrl) {
                    if (!ctrl) {
                        return;
                    }
                    console.log('statePreloader loaded', $rootScope.angularLoader);
                    /*
                    ctrl.$parsers.push(function (val) {
   
                        var good = val.replace(/[^0-9]+/g, '');
                        if (val !== good) {
                            ctrl.$setViewValue(good);
                            ctrl.$render();
                        }
                        return good;
                    });*/
                } ////
            };
        }]);
    })(statePreloader = app.statePreloader || (app.statePreloader = {}));
})(app || (app = {}));
function template() {
    return "<div id=\"preloader\" class=\"overlay-loader\" class='fade-out'>\n    <div class=\"loader-background\"></div>\n\n   <div class=\"sk-folding-cube\">\n      <div class=\"sk-cube1 sk-cube\"></div>\n      <div class=\"sk-cube2 sk-cube\"></div>\n      <div class=\"sk-cube4 sk-cube\"></div>\n      <div class=\"sk-cube3 sk-cube\"></div>\n    </div>\n \n  </div>";
}
